<template lang="pug">
    modal(name="MJKey" class="add-mj-key-modal-wrap" :adaptive="true" width="100%" height="100%")
        .add-mj-key-modal
            ._wrap
                ._content
                    ._content-inner
                        form._form(@submit.prevent="activate")
                            ._close-btn(@click="$modal.hide('MJKey')")
                                svg-icon(icon-name="cross")._close-icon
                            ._form-title Введите данные
                            input(v-model="token" placeholder="Токен")._input
                            ._error(v-if="tokenError") Введите токен
                            input(v-model="link" placeholder="Ссылка на канал")._input
                            ._error(v-if="linkError") Введите валидную ссылку
                            input(v-model="alias" placeholder="Название аккаунта")._input
                            multiselect(v-model="proxy" :options="proxies" label="name" selectLabel="" deselectLabel="" selectedLabel="" placeholder="Прокси сервер")._input.-select
                            ._error(v-if="proxyError") Выберите прокси сервер
                            ._error.-big(v-if="error") {{ error }}
                            ui-button(color="gray" type="bordered" text="Активировать")._btn
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import multiselect from "vue-multiselect/src";
import createMJKeyMutation from "@/graphql/mutations/createMJKey.mutation.graphql";
import gql from "graphql-tag";

export default {
    name: "MJKeyModal",
    components: { UiButton, multiselect },
    data() {
        return {
            token: "",
            link: "",
            alias: "",
            proxy: {
                name: "discord1.merlinface.com",
                value: "discord1.merlinface.com",
            },
            proxies: [
                {
                    name: "discord1.merlinface.com",
                    value: "discord1.merlinface.com",
                },
                {
                    name: "discord2.merlinface.com",
                    value: "discord2.merlinface.com",
                },
                {
                    name: "discord3.merlinface.com",
                    value: "discord3.merlinface.com",
                },
                {
                    name: "discord4.merlinface.com",
                    value: "discord4.merlinface.com",
                },
                {
                    name: "discord5.merlinface.com",
                    value: "discord5.merlinface.com",
                },
                {
                    name: "discord6.merlinface.com",
                    value: "discord6.merlinface.com",
                },
                {
                    name: "discord7.merlinface.com",
                    value: "discord7.merlinface.com",
                },
                {
                    name: "discord8.merlinface.com",
                    value: "discord8.merlinface.com",
                },
            ],
            tokenError: false,
            linkError: false,
            proxyError: false,
            error: false,
            addCount: 0,
        };
    },
    computed: {
        formattedToken: (state) =>
            state.token ? state.token.replace(/"/g, "").trim() : "",
        parsedLink: (state) => (state.link ? state.link.split("/") : []),
        serverId: (state) =>
            state.parsedLink.length ? state.parsedLink[4] : "",
        channelId: (state) =>
            state.parsedLink.length ? state.parsedLink[5] : "",
    },
    mounted() {},
    methods: {
        activate() {
            this.tokenError = !this.formattedToken;
            this.proxyError = !this.proxy;
            this.linkError = !this.serverId || !this.channelId;

            if (this.tokenError || this.proxyError || this.linkError) return;

            this.$apollo
                .mutate({
                    mutation: gql(createMJKeyMutation),
                    client: "chatClient",
                    variables: {
                        keys: [
                            {
                                token: this.formattedToken,
                                server_id: this.serverId,
                                channel_id: this.channelId,
                                server: this.proxy.value,
                                alias: this.alias,
                                fast_time_remaining: 0,
                            },
                        ],
                    },
                })
                .then(() => {
                    this.$emit("add-new");
                    this.$modal.hide("MJKey");
                })
                .catch((e) => {
                    if(e.graphQLErrors[0].message === "Failed to log in") {
                        this.error = "Проверьте, всё ли в порядке с аккаунтом";
                        return;
                    }
                    this.error = "Этот аккаунт уже добавлен";
                });
        },
    },
};
</script>

<style lang="scss" src="./add-mj-key-modal.scss"></style>
