<template lang="pug">
    .content__main.-without-right-aside
        .midjourney-keys
            ._head
                ._title Управление ключами Midjourney
                ._head-group
                    ui-button(text="Статистика" size="small" type="text" @click.native="$modal.show('MJStats')")
                    ui-button(text="Добавить ключ" size="small" color="gray" type="bordered" @click.native="$modal.show('MJKey')")
            ._list(v-if="isEditor || isAdmin")
                ._key
                    ._key-id Название
                    ._key-token Токен
                    ._key-server Сервер
                    ._key-channel Канал
                    ._key-channel Статус
                    ._key-hours Осталось часов
                ._key(v-for="(key, index) in keys" :key="key.token" v-if="!key.deleted")
                    ._key-id(:title="key.id") {{ key.alias }}
                    ._key-token(:title="key.token") {{ key.token }}
                    ._key-server {{ key.server_id }}
                    ._key-channel {{ key.channel_id }}
                    ._key-channel(v-if="key.blocked") Заблокирован
                    ._key-channel(v-else-if="key.disabled")
                        span(@click="confirmCheck(key)") Включить
                    ._key-channel(v-else-if="!key.status") Кончилась подписка
                    ._key-channel(v-else) {{ index > 0 ? !keys[index - 1].disabled && !keys[index - 1].blocked && keys[index - 1].status ? "Ожидает" : "Работает" : "Работает" }}
                    ._key-hours {{ (key.fast_time_remaining / 60 / 60).toFixed(2) }} ч.
                        svg-icon(icon-name="cross" @click.native="confirmDelete(key)")._delete-icon
        m-j-key-modal(v-if="isEditor || isAdmin" @add-new="getKeys")
        m-j-stats(v-if="isEditor || isAdmin")
</template>

<script>
import gql from "graphql-tag";
import UiButton from "../components/ui/ui-button/ui-button.vue";
import getMJKeysQuery from "../graphql/queries/getMJKeys.query.graphql";
import deleteMJKeyMutation from "../graphql/mutations/deleteMJKey.mutation.graphql";
import checkMJKeyMutation from "../graphql/mutations/checkMJKey.mutation.graphql";
import MJKeyModal from "@/components/modals/add-mj-key-modal/add-mj-key-modal.vue";
import MJStats from "@/components/modals/mj-stats/mj-stats.vue";

export default {
    name: "MidjourneyKeys",
    components: { MJStats, MJKeyModal, UiButton },
    data() {
        return {
            keys: [],
            keyToDelete: null,
            keyToCheck: null,
            checkResult: "",
        };
    },
    computed: {},
    async mounted() {
        if (this.isEditor || this.isAdmin) {
            await this.getKeys();
            return;
        }
        await this.$router.push("/");
    },
    methods: {
        async getKeys() {
            this.keys = [];
            await this.$apollo
                .query({
                    query: gql(getMJKeysQuery),
                    fetchPolicy: "no-cache",
                    client: "chatClient",
                })
                .then((r) => {
                    r.data.getMJKeys.forEach((key) => {
                        this.$set(key, "deleted", false);
                        this.keys.push(key);
                    });
                })
                .catch(() => {
                    this.error = true;
                });
        },
        confirmDelete(key) {
            this.keyToDelete = key;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите удаление",
                text: "Вы действительно хотите удалить ключ? После удаления пользователи не смогут увеличивать картинки, которые были сгенерированы с помощью этого ключа",
                buttons: true,
                confirmText: "Удалить",
                callback: this.deleteKey,
            });
        },
        confirmCheck(key) {
            this.keyToCheck = key;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите проверку аккаунта",
                text: "Вы действительно хотите включить ключ? Делайте это только тогда, когда проверили аккаунт",
                buttons: true,
                confirmText: "Включить",
                callback: this.checkKey,
            });
        },
        deleteKey() {
            this.$apollo
                .mutate({
                    mutation: gql(deleteMJKeyMutation),
                    variables: {
                        id: [+this.keyToDelete.id],
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.keyToDelete.deleted = true;
                    this.keyToDelete = null;
                });
        },
        checkKey() {
            this.$apollo
                .mutate({
                    mutation: gql(checkMJKeyMutation),
                    variables: {
                        key: +this.keyToCheck.id,
                    },
                    client: "chatClient",
                })
                .then((r) => {
                    if (r.data.checkMJKey) {
                        this.keyToCheck.disabled = false;
                    }
                    this.keyToCheck = null;
                });
        },
    },
};
</script>

<style lang="scss">
.midjourney-keys {
    $root: &;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        position: relative;
        height: 30.5px;
        padding: 0 10px;
        max-width: 120px;
        color: #fff;
        transition: 0.4s;
        border: 0;
        background: #323232;
        z-index: 1;
        margin-right: 5px;
        text-align: center;

        &_big {
            width: 220px;
            max-width: 220px;
            text-align: left;
        }
    }

    &__error {
        color: red;
        font-size: 12px;
        margin-top: 7px;
    }

    &__list {
        margin-top: 20px;
        height: calc(100vh - 200px);
    }

    &__key {
        display: flex;
        align-items: center;
        padding: 10px;
        transition: 0.4s;

        &.new {
            background: rgba($gold, 0.1);
        }

        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }

        &:first-child {
            #{$root} {
                &__key-token,
                &__key-id,
                &__key-channel,
                &__key-server,
                &__key-hours {
                    color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    &__key-token {
        width: 14%;
        color: #fff;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__key-server {
        width: 22%;
        color: #fff;
        text-align: center;
    }

    &__key-id {
        display: flex;
        align-items: center;
        color: $gold;
        width: 24%;

        &.used {
            text-decoration: line-through;
            color: rgba(255, 255, 255, 0.2);
        }
    }

    &__key-channel {
        width: 22%;
        color: #fff;
        margin-left: 10px;
        text-align: center;

        span {
            border-bottom: 1px solid rgba($gold, 0.5);
            transition: 0.4s;

            &:hover {
                border-bottom-color: $gold;
                cursor: pointer;
            }
        }
    }

    &__key-hours {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        color: #fff;
        text-align: center;
    }

    &__delete-icon {
        width: 20px;
        height: 20px;
        margin-left: 30px;
        fill: red;

        &:hover {
            cursor: pointer;
        }
    }

    &__message-loading {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    &__message-loader {
        position: initial;
    }

    &__list-group {
        display: flex;
        align-items: center;
    }

    &__select-all {
        color: #fff;
        margin-right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    &__copy-all {
        color: $gold;
        margin-left: auto;
    }

    &__delete-all {
        color: red;
        margin-left: auto;
    }
}
</style>
